import _debounce from "lodash/debounce";
import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import filters from '@filters/filters';
import { formatPx2Rem } from '@utils/index';
import { isEmpty, dateFilter } from '@utils/utils';
import { admitBookAPI } from '@api/appointment';
export default {
  name: 'AppointmentDetail',
  mixins: [common, filters],
  components: {},
  data() {
    return {
      headerTitle: '预约详情',
      clientInfo: {
        // 客户信息
        baseInfoFields: [
        // 基本信息
        {
          title: '姓名',
          key: 'bookerName',
          type: 'value'
        }, {
          title: '性别',
          key: 'bookerSex',
          type: 'value'
        }, {
          title: '手机号码',
          key: 'bookerPhone',
          type: 'value'
        }, {
          title: '意向户型/面积/朝向',
          key: 'expectLayouts',
          type: 'value'
        }, {
          title: '职业',
          key: 'bookerProfession',
          type: 'value'
        }, {
          title: '来源渠道',
          key: 'source',
          type: 'value'
        }, {
          title: '备注',
          key: 'remark',
          type: 'value'
        }],
        baseInfo: {}
      },
      isLoading: false,
      // 是否在加载数据
      isRefreshPage: false,
      // 是否需要刷新页面
      noDataMessage: '',
      // 列表为空时的提示文案
      detailInfo: {} // 详情信息
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {},
  methods: {
    formatPx2Rem,
    // 日期格式化
    dateFilter,
    // 空字段过滤
    fieldEmptyFilter(data, returnEmpty = '无') {
      return !isEmpty(data) ? data : returnEmpty;
    },
    // 格式化基本信息
    formatBaseInfo() {
      let {
        detailInfo
      } = this;
      this.clientInfo.baseInfo = {
        bookerName: this.fieldEmptyFilter(detailInfo.bookerName),
        bookerSex: this.fieldEmptyFilter(detailInfo.bookerSex),
        bookerPhone: this.fieldEmptyFilter(detailInfo.bookerPhone),
        expectLayouts: this.fieldEmptyFilter(detailInfo.expectLayouts),
        bookerProfession: this.fieldEmptyFilter(detailInfo.bookerProfession),
        source: this.fieldEmptyFilter(detailInfo.source),
        remark: this.fieldEmptyFilter(detailInfo.remark)
      };
    },
    handleSubmit: _debounce(function () {
      this.$dialog.confirm({
        title: '完成接待',
        message: '是否确认完成接待？',
        confirmButtonColor: '#D8B084'
      }).then(async () => {
        // on confirm
        await this.submitAppointmentData();
      }).catch(() => {
        // on cancel
      });
    }, 200),
    async handleCallback({
      isError,
      errorMessage = undefined
    }) {
      // 回调处理
      if (isError) {
        this.$toast(errorMessage || '获取失败!');
        /* if (errorMessage && errorMessage.includes('timeout')) {
          // 判断是否是网络请求超时
          this.isRefreshPage = true
          this.noDataMessage = '网络请求超时'
        } else if (errorMessage && errorMessage.includes('502')) {
          // 判断是否是服务器错误
          this.isRefreshPage = true
          this.noDataMessage = '服务器错误，502错误'
        } */
      }
    },

    async submitAppointmentData({
      isShowLoading
    } = {
      isShowLoading: true
    }) {
      // 完成接待
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          id
        } = this;
        let response = await admitBookAPI({
          id
        });
        let result = response.data;
        // console.log(result)
        if (`${result.code}` === '10000') {
          // 请求成功
          // 接口请求处理成功之后回跳
          this.$toast({
            message: '提交成功！',
            onClose: () => {
              this.$router.go(-1);
            }
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '完成接待失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '完成接待失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    async getDetailData({
      isShowLoading
    } = {
      isShowLoading: true
    }) {
      // 获取详情数据
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          id
        } = this;
        let response = await this.$store.dispatch('appointment/GetAppointmentDetail', {
          id
        });
        // let result = response.data
        let result = response;
        // console.log(result)
        if (`${result.code}` === '10000') {
          // 请求成功
          let resultData = result.data || {};
          this.detailInfo = resultData;
          this.formatBaseInfo();
          await this.handleCallback({
            isError: false
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '获取列表失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取列表失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    async initData() {
      await this.getDetailData();
    },
    goApply() {
      let {
        id
      } = this;
      this.$router.push({
        name: 'AppointmentApply',
        query: {
          id
        }
      });
    }
  },
  filters: {},
  watch: {},
  created() {},
  mounted() {
    this.initData();
  },
  activated() {
    this.initData();
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  }
};