var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-flexbox cyui-detail-header"
        }, [_c('div', {
          staticClass: "cyui-flexbox-item cyui-detail-header-content"
        }, [_c('div', {
          staticClass: "cyui-detail-header-title"
        }, [_vm.detailInfo.status === 0 ? _c('span', [_vm._v("接待未完成")]) : _vm.detailInfo.status === 1 ? _c('span', [_vm._v("接待已完成")]) : _vm._e()]), _vm.detailInfo.status === 0 ? _c('div', {
          staticClass: "cyui-detail-header-brief"
        }, [_c('span', [_vm._v("预计到访时间：")]), _c('span', [_vm._v(_vm._s(_vm.detailInfo.bookVisitTime))])]) : _vm.detailInfo.status === 1 ? _c('div', {
          staticClass: "cyui-detail-header-brief"
        }, [_c('span', [_vm._v("到访时间：")]), _c('span', [_vm._v(_vm._s(_vm.detailInfo.factVisitTime))])]) : _vm._e()]), _c('div', {
          staticClass: "cyui-detail-header-extra"
        }, [_c('div', {
          staticClass: "cyui-detail-header-status"
        }, [_vm.detailInfo.status === 0 ? _c('svg-icon', {
          attrs: {
            "icon-class": "status_4"
          }
        }) : _vm.detailInfo.status === 1 ? _c('svg-icon', {
          attrs: {
            "icon-class": "status_3"
          }
        }) : _vm._e()], 1)])]), _c('div', {
          staticClass: "cyui-section"
        }, [_c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('cyui-field-list-info', {
          attrs: {
            "fields": _vm.clientInfo.baseInfoFields,
            "data": _vm.clientInfo.baseInfo,
            "title-width": `${_vm.formatPx2Rem(220)}rem`
          }
        })], 1)])])])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_vm.detailInfo.status === 0 ? _c('div', {
          staticClass: "cyui-actionbar"
        }, [_c('div', {
          staticClass: "cyui-actionbar-btn"
        }, [_c('van-button', {
          attrs: {
            "block": "",
            "type": "primary",
            "size": "large"
          },
          on: {
            "click": _vm.goApply
          }
        }, [_vm._v("补充调整信息")])], 1), _c('div', {
          staticClass: "cyui-actionbar-btn"
        }, [_c('van-button', {
          attrs: {
            "block": "",
            "type": "primary",
            "plain": "",
            "size": "large"
          },
          on: {
            "click": _vm.handleSubmit
          }
        }, [_vm._v("确认到访并完成接待")])], 1)]) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };